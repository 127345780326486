<template>
  <div v-if="isshow" class="community-show" @click="showPopover = ''">
    <div class="show-content">
      <div class="show-content-title">{{info.title}}</div>
      <div class="show-content-user">
        <img class="show-content-user-avatar" :src="info.user_profile && info.user_profile.wechat.avatar"/>
        <div class="show-content-user-t">
          <div class="show-content-user-t-name vertical-center">{{info.user_profile && info.user_profile.wechat && info.user_profile.wechat.nickname}}
            <img src="~assets/images/public/vip1.png" v-if="info.user_profile && info.user_profile.user && info.user_profile.user.level == 'vip1'" class="vipimg">
            <img src="~assets/images/public/captain.png" v-if="info.user_profile && info.user_profile.is_team_master "  class="captain" />
            <img src="~assets/images/public/captain-s.png" v-if="info.user_profile && info.user_profile.is_group_team_master "  class="captain-s" />
            <img :src="info.user_profile.user_label.picture" v-if="info.user_profile && info.user_profile.user_label && info.user_profile.user_label.picture" class="labelimg">
            <img src="~assets/images/public/36ce.png" v-else class="labelimg">
          </div>
          <div class="show-content-user-t-info">
            <div class="show-content-user-t-info-l vertical-center">
              {{info.user_profile && info.user_profile.team  && info.user_profile.team.name}} | 编号:{{info.user_profile && info.user_profile.number}}
              <img src="~assets/images/index/copy.png" class="show-content-user-t-info-l-img copy-btn" alt=""  :data-clipboard-text=" info.user_profile && info.user_profile.team && info.user_profile.team.name + ' | 编号:' + info.user_profile.number" @click="copy">
            </div>
            <div class="show-content-user-t-info-r">
              {{info.create_time}}
            </div>
          </div>
        </div>
      </div>

      <div class="show-content-brief">
        {{info.content}}
      </div>
      <div class="show-content-file" v-for="(file,index) in info.resources" :key="index">
        <img class="show-content-file-l" :src="file.url"/>
        <div class="show-content-file-r  white-space">
          <div class="show-content-file-r-t white-space">{{file.name}}</div>
          <div class="show-content-file-r-t">{{(file.size / 1024 / 1024).toFixed(2) > 0.01 ? (file.size / 1024 / 1024).toFixed(2):0.01}}MB</div>
        </div>
      </div>
      <div class="show-content-footer">
        <div class="show-content-footer-l vertical-center">浏览{{$handle.tranNumber( info.browse_num,3)}} · 兑换{{$handle.tranNumber( info.down_num,3)}} </div>
        <div class="show-content-footer-r vertical-center"><span >{{info.credit}}</span>积分
          <div class="show-content-footer-r-e" @click="exchange()">立即兑换</div>
        </div>
      </div>
    </div>
    <div class="show-line"></div>
    <div class="show-comment">
      <div class="show-comment-title">评论 {{$handle.tranNumber( info.comment_num,3)}}</div>
      <van-list
        v-model:loading="electronloading"
        :finished="electronfinished"
        :finished-text="commentList.length > 5 ? '没有更多了':''"
        @load="electrononLoad"
      >
        <NoData v-if="commentList.length == 0 && info.comment_num == 0" :bottomBg="true" ></NoData>
        <NoData v-if="commentList.length == 0 && info.comment_num != 0" :bottomBg="true" type='right'></NoData>
        <div class="show-comment-item" v-for="(item,index) in commentList" :key="index">
          <div class="show-comment-item-user">
            <div class="show-comment-item-user-l">
              <img class="show-comment-item-user-l-img" :src="item.user_profile && item.user_profile.wechat && item.user_profile.wechat.avatar"/>
              <div class="show-comment-item-user-l-t">
                <div class="show-comment-item-user-l-t-name vertical-center">{{item.user_profile && item.user_profile.wechat && item.user_profile.wechat.nickname}}
                  <img src="~assets/images/public/vip1.png" v-if="item.user_profile && item.user_profile.user && item.user_profile.user.level == 'vip1'" class="vipimg">
                  <img src="~assets/images/public/captain.png" v-if="item.user_profile && item.user_profile.is_team_master "  class="captain" />
                  <img src="~assets/images/public/captain-s.png" v-if="item.user_profile && item.user_profile.is_group_team_master "  class="captain-s" />
                  <img :src="item.user_profile.user_label.picture" v-if="item.user_profile && item.user_profile.user_label && item.user_profile.user_label.picture" class="labelimg">
                  <img src="~assets/images/public/36ce.png" v-else class="labelimg">
                </div>
                <div class="show-comment-item-user-l-t-info">{{item.user_profile && item.user_profile.team  && item.user_profile.team.name}}</div>
              </div>
            </div>
          </div>
          <!-- <van-popover :show="showPopover == 'hd' + item.id " :actions="actions" @select="onSelectcopy" placement="top" trigger="manual">
       <template #reference> -->
          <div class="show-comment-item-content"  >
            {{item.content}}
            <!-- <span style="color: #FF6B29;">展开</span> -->
          </div>
          <!-- </template>
 </van-popover> -->
          <div class="show-comment-item-comment" v-if="item.comments && item.comments.length">
            <div class="show-comment-item-comment-item" v-for="(citem,indexs) in item.comments" :key="indexs" @click="evaluate(citem.id)">
              <div v-if="citem.comment_id == citem.parent_id">{{citem.comment_user_profile && citem.comment_user_profile.wechat && citem.comment_user_profile.wechat.nickname}}<img src="~assets/images/public/vip1.png" v-if="citem.comment_user_profile && citem.comment_user_profile.user && citem.comment_user_profile.user.level == 'vip1'" class="vipimg"><img :src="citem.comment_user_profile.user_label.picture" v-if="citem.comment_user_profile && citem.comment_user_profile.user_label && citem.comment_user_profile.user_label.picture" class="labelimg"><img src="~assets/images/public/36ce.png" v-else class="labelimg">：
              </div>
              <div v-if="citem.comment_id != citem.parent_id">{{citem.comment_user_profile && citem.comment_user_profile.wechat && citem.comment_user_profile.wechat.nickname}}<img src="~assets/images/public/vip1.png" v-if="citem.comment_user_profile && citem.comment_user_profile.user && citem.comment_user_profile.user.level == 'vip1'" class="vipimg"><img :src="citem.comment_user_profile.user_label.picture" v-if="citem.comment_user_profile && citem.comment_user_profile.user_label && citem.comment_user_profile.user_label.picture" class="labelimg"><img src="~assets/images/public/36ce.png" v-else class="labelimg">
                 回复 {{citem.parent_comment_user_profile && citem.parent_comment_user_profile.wechat && citem.parent_comment_user_profile.wechat.nickname}}<img src="~assets/images/public/vip1.png" v-if="citem.parent_comment_user_profile && citem.parent_comment_user_profile.user && citem.parent_comment_user_profile.user.level == 'vip1'" class="vipimg"><img :src="citem.parent_comment_user_profile.user_label.picture" v-if="citem.parent_comment_user_profile && citem.parent_comment_user_profile.user_label && citem.parent_comment_user_profile.user_label.picture" class="labelimg"><img src="~assets/images/public/36ce.png" v-else class="labelimg">
                ：</div>
              <!-- <van-popover :show="showPopover == 'pl' + citem.id " :actions="actions" @select="onSelectcopy" placement="top" trigger="manual">
  <template #reference> -->
              <span>{{citem.content}}</span>
              <!-- </template>
</van-popover> -->
              <span style="color: #FF6B29;" @click.stop="getreplyList(item.id)"   v-if="indexs == 2 && item.isLimit == 1">更多</span>
            </div>

          </div>
          <div class="show-comment-item-footer">
            <div class="show-comment-item-footer-l">{{item.create_time}}回复</div>
            <div class="show-comment-item-footer-r">
              <div class="show-comment-item-footer-r-a" @click="evaluate(item.id)">
                <img src="~assets/images/index/comment.png"  class="show-comment-item-footer-img"/>
                {{$handle.tranNumber( item.comment_num,3)}}
              </div>
              <div class="show-comment-item-footer-r-a" @click="Praise(item.id,'comment')">
                <img  v-if="item.is_praise == 0"  src="~assets/images/index/up.png"  class="show-comment-item-footer-img"/>
                <img  v-if="item.is_praise == 1" src="~assets/images/index/up-a.png"  class="show-comment-item-footer-img"/>
                {{$handle.tranNumber( item.praise_num,3)}}
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <div class="btmbox"></div>
    </div>
    <div class="footer">
      <div class="foot">
        <div :class="info.is_favorite == 0 ? 'foot-l vertical-center':'foot-l foot-l-a vertical-center'" @click="collect(info.id)">
          <img v-if="info.is_favorite == 0" src="~assets/images/index/star.png" />
          <img v-if="info.is_favorite == 1" src="~assets/images/index/star-a.png" />
          {{$handle.tranNumber( info.collect_num,3)}}
        </div>
        <div :class="info.is_praise == 0 ? 'foot-c vertical-center':'foot-c foot-c-a vertical-center'"  @click="Praise(info.id,'electron')">
          <img  v-if="info.is_praise == 0" src="~assets/images/index/up.png" />
          <img  v-if="info.is_praise == 1" src="~assets/images/index/up-a.png" />
          {{$handle.tranNumber( info.praise_num,3)}}
        </div>
        <img class="foot-r-i" src="~assets/images/index/comment-e-img.png" @click="evaluate('0','0')" />
      </div>
      <div class="safety-height"></div>

    </div>
    <van-popup
      class="hdpop"
      v-model:show="show2"
      position="bottom"
      :style="{height: '32%'}"
    >
      <van-field
        v-model="content"
        placeholder="请输入内容"
        border="{{ false }}"
        rows="5"
        type="textarea"
        maxlength='300'
        show-word-limit
        cursor-spacing='170'
      >

      </van-field>
      <div class="popup-btn" @click="commentsub()">确定</div>
      <div class="safety-height bagbai"></div>
    </van-popup>
    <van-toast id="van-toast" />
    <van-popup
      class="hdpop"
      v-model:show="popshow"
      closeable
      round
      style="overflow: initial"
    >
      <div class="expop">
        <img :src="info.user_profile && info.user_profile.wechat && info.user_profile.wechat.avatar" class="expop-img"/>
        <div class="expop-name">{{info.user_profile && info.user_profile.wechat && info.user_profile.wechat.nickname}}</div>
        <div class="expop-num"> {{info.user_profile && info.user_profile.team && info.user_profile.team.name}} | 编号:{{info.user_profile.number}}</div>
        <div class="expop-info"> {{info.content}}</div>
        <div class="expop-sub" @click="confirm()">确认兑换</div>
      </div>
    </van-popup>
  </div>
  <van-loading size="24px" v-else class="align-center">加载中...</van-loading>
</template>

<script>
import NoData from "components/NoData";
import UserInfo from "components/userInfo";
import _ from 'lodash'
import Clipboard from 'clipboard'
export default {
  name: "resources.show",
  data(){
    return{
      show: false,
      show2: false,
      value: '',
      info:{},
      id:'',
      commentList:[],
      page:1,
      load:true,
      adoption_value:'',
      model_id:'',
      parent_id:'',
      content:'',
      userInfo:{},
      replyList:[],
      osscdn:'',
      one:true,
      electronloading:false,
      electronfinished:true,
      isshow:false,
      timeOutEvent: 0,
      showPopover:'',
      actions: [
        { text: '复制' }
      ],
      copyvalue:'',
      type:'',
      uuid:'',
      popshow:false
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    if (this.$route.query.uuid) {
      this.uuid = this.$route.query.uuid
    }
    this.$store.commit('M_ACT','2')
    this.getinfo()

  },
  methods:{
    // 点击复制事件
    copy(){
      var clipboard = new Clipboard('.copy-btn');
      clipboard.on('success', e => {
        this.$toast('复制成功');
        // 释放内存
        clipboard.destroy()
      });
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy()
      })
    },
    onSelectcopy(){
      var input = document.createElement("input"); // 创建input对象
      input.value = this.copyvalue; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$toast('复制成功');
      this.showPopover=''
    },
    gtouchstart (value,id) {

      // 开始触摸
      this.timeOutEvent = setTimeout(() => {
        this.showPopover = id
        this.copyvalue = value
        // 长按1秒
        this.timeOutEvent = 0
      }, 1000)
    },
    gtouchmove () {
      // clearTimeout(this.timeOutEvent)
      // this.timeOutEvent = 0
    },
    gtouchend () {
      clearTimeout(this.timeOutEvent)
      this.timeOutEvent = 0
    },
    reset(){
      this.commentList=[]
      this.replyList= []
      this.page=1
      this.load=true
      this.isshow = false
      this.getinfo()
    },
    //详情
    getinfo(){
      if(!this.$cache.getIsLogin()) {
        let url = this.$api.electron    + '/' + this.id
        this.$http.get(url, false).then(res => {
          if(res.data.success){
            this.isshow = true
            this.info=res.data.data
            // if(this.info.answer_visible == 1){
            //   this.getcommentList()
            // }else{
            //   if(this.one){
            //     this.$toast('回答受限，不予展示');
            //   }
            //     this.one=false
            // }
            if(res.data.data.status == 1 ){
              this.getcommentList()
            }else if(res.data.data.status == 0 ){
              if(this.$store.getters.userInfo.id != res.data.data.user_id){
                this.$store.dispatch('A_ERROR_TEXT',{
                  code_num: '404',
                  error_text: '您访问的内容不存在'
                })
                setTimeout(()=>{
                  this.$router.push({path: '/error'})
                },500)
              }
            }
          }
        })
      }else{
        let url
        if(this.type == 'news'){
          url = this.$api.electron    + '/' + this.id + '?notifiable_uuid=' + this.uuid
        }else{
          url = this.$api.electron    + '/' + this.id
        }
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            this.isshow = true
            this.info=res.data.data
            // if(this.info.answer_visible == 1){
            //   this.getcommentList()
            // }else{
            //   if(this.one){
            //     this.$toast('回答受限，不予展示');
            //   }
            //     this.one=false
            // }
            if(res.data.data.status == 1 ){
              this.getcommentList()
            }else if(res.data.data.status == 0 ){
              if(this.$store.getters.userInfo.id != res.data.data.user_id){
                this.$store.dispatch('A_ERROR_TEXT',{
                  code_num: '404',
                  error_text: '您访问的内容不存在'
                })
                setTimeout(()=>{
                  this.$router.push({path: '/error'})
                },500)
              }
            }
          }
        })
      }

    },
    //评论列表
    getcommentList(){
      if(!this.$cache.getIsLogin()) {
        let url = this.$api.Comment + '?comment_id=0&model_type=electron&pageSize=4&model_id=' + this.id + '&page=' + this.page
        this.$http.get(url, false).then(res => {
          if(res.data.success){
            if(res.data.data.length){
              this.commentList= this.commentList.concat(res.data.data)
              this.page=this.page + 1
              this.electronfinished = false
              this.electronloading = false;
            }
            if(res.data.data.length < 4){
              this.load=false
              this.electronfinished = true
            }
          }
        })
      }else{
        let url = this.$api.Comment + '?comment_id=0&model_type=electron&pageSize=4&model_id=' + this.id + '&page=' + this.page
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
              this.commentList= this.commentList.concat(res.data.data)
              this.page=this.page + 1
              this.electronfinished = false
              this.electronloading = false;
            }
            if(res.data.data.length < 4){
              this.load=false
              this.electronfinished = true
            }
          }
        })
      }

    },
    getreplyList(id){
      let reply = []
      let index = this.commentList.indexOf(this.commentList.find(item => {return item.id == id}))
      let url = this.$api.Comment + '?model_type=electron&comment_id='+ id +'&model_id=' + this.id
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          this.commentList[index].comments = this.commentList[index].comments.concat(res.data.data)
          this.commentList[index].isLimit = 0
          this.commentList = this.commentList
        }
      })
    },
    //点赞
    Praise(id,type){
      if(!this.$cache.getIsLogin()) {
        this.$store.commit('M_LOGIN_POP', true)
        return
      }
      if(this.info.status == 0){
        return
      }
      let url = this.$api.communityPraise
      let data = {
        model_id:id,
        model_type:type
      }
      this.$http.post(url, data, true).then(res => {
        if(res.data.success){
          this.reset()
          // this.commentList()
        }
      })
    },
    //收藏
    collect(id){
      if(!this.$cache.getIsLogin()) {
        this.$store.commit('M_LOGIN_POP', true)
        return
      }
      if(this.info.status == 0){
        return
      }
      let url = this.$api.communityCollect
      let data = {
        model_id:id,
        model_type:'electron'
      }
      this.$http.post(url, data, true).then(res => {
        if(res.data.success){
          //   wx.showToast({
          //     title: '收藏成功',
          //     icon: 'success',
          //     duration: 2000
          // })
          this.reset()
          // this.commentList()
        }
      })
    },
    onClickShow(id) {
      if(!this.$cache.getIsLogin()) {
        this.$store.commit('M_LOGIN_POP', true)
        return
      }
      this.show = true
      this.adoption_value = id
    },
    //兑换
    exchange(){
      if(!this.$cache.getIsLogin()) {
        this.$store.commit('M_LOGIN_POP', true)
        return
      }
      let url = this.$api.communityDownloadConfirm + '/' + this.info.id
      this.$http.get(url,  {}, true).then(res => {
        if(res.data.success){
          this.popshow=true
        }else{
          this.$toast(res.data.message);

        }
      })
    },
    confirm: _.debounce(function(){
      let url = this.$api.communityDownloadElectron
      let data ={
        electron_id:this.info.id
      }
      this.$http.post(url,  data, true).then(res => {
        if(res.data.success){
          this.$toast('兑换成功');
          this.popshow= false
          this.$router.push({path: '/user/download',query: {act: this.info.type}})
        }else{
          this.$toast(res.data.message);
        }
      })
    },500),
    onClickHide() {
      this.show = false
    },
    onClickHidec() {
      let url = this.$api.communityAdoption + '/' + this.adoption_value
      this.$http.put(url, {}, true).then(res => {
        if(res.data.success){
          this.show = false
          this.reset()
        }else{
          this.show = false
          this.$toast(res.data.message);
        }
      }).catch(error =>{
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
    },
    //评价
    evaluate(id){
      if(!this.$cache.getIsLogin()) {
        this.$store.commit('M_LOGIN_POP', true)
        return
      }
      this.show2 = true
      this.model_id = id
      this.parent_id = id
    },
    onClose(){
      this.show2 = false
    },
    //评论提交
    commentsub(){
      if(this.content == ''){
        this.$toast('内容不能为空');
        return
      }
      let url = this.$api.CommunityComment
      let data = {
        model_id:this.id,
        parent_id:this.parent_id,
        content:this.content,
        model_type:'electron'
      }
      this.$http.post(url, data, true).then(res => {
        if(res.data.success){
          this.show2 = false
          this.content = ''
          this.value = ''
          this.reset()
        }else{
          this.show = false
          this.$toast(res.data.message);
        }
      }).catch(error =>{
        if (error.response.status == 422) {
          this.$toast(error.response.data.message);
        }
      })
    },
    electrononLoad(){
      if(this.load){
        this.getcommentList()
      }
    },
  },
  components:{
    UserInfo,
    NoData
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/community/show.scss";
.vipimg{
  width: 28/$r;
  height: 25/$r;
  margin-left: 7/$r;
}
.captain{
  width: 77/$r;
  height: 29/$r;
  margin-left: 7/$r;
}
.captain-s{
  width: 91/$r;
  height: 26/$r;
  margin-left: 7/$r;
}

.show-content-brief{
  margin-bottom: 20/$r;
}
</style>
<style lang="scss">
$r: 750/16/1rem;
// .hdpop{
//   position: fixed;
// }
.bagbai{
  background: #fff;
}
.community-show .van-field__control{
  background: #F7F7F7;
  border-radius: 10/$r;
  font-size: 24/$r;
  font-weight: 400;
  color: #B7BBBF;
  padding: 20/$r;
  box-sizing: border-box;
  height: 280/$r;
}
.community-show .van-cell:after{
  border: unset;
}
.community-show .show-content-file{
  height: 120/$r;
  background: #F7F7F7;
  border-radius: 6/$r;
  display: flex;
  padding: 25/$r 20/$r;
  box-sizing: border-box;
  margin-bottom: 20/$r;
}
.community-show .show-content-file .show-content-file-l{
  width: 70/$r;
  height: 70/$r;
  margin-right: 16/$r;
}
.community-show .show-content-file .show-content-file-r{
  flex: 1;
  font-size: 26/$r;
  font-weight: 400;
  color: #848484;
}

.show-content-footer-r span{
  font-size: 32/$r;
}
.show-content-footer-r-e{
  width: 130/$r;
  height: 48/$r;
  background: #FE8650;
  border-radius: 4/$r;
  font-size: 26/$r;
  font-weight: 400;
  color: #FDFDFD;
  text-align: center;
  line-height: 48/$r;
  margin-left: 27/$r;
}
.expop{
  width: 600/$r;
  height: 525/$r;
  background: #FFFFFF;
  border-radius: 20/$r;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.expop .expop-img{
  width: 140/$r;
  height: 140/$r;
  background: #E5E8EB;
  border: 2/$r solid #EBEFF2;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  top: -70/$r;
  left: 230/$r;
}
.expop .expop-name{
  font-size: 30/$r;
  font-weight: 500;
  color: #313A43;
  text-align: center;
  margin: 90/$r 0 10/$r;
}
.expop .expop-num{
  font-size: 24/$r;
  font-weight: 400;
  color: #93999F;
  text-align: center;
}
.expop .expop-info{
  width: 560/$r;
  height: 180/$r;
  background: #F7F7F7;
  padding: 36/$r 22/$r;
  border-radius: 6/$r;
  font-size: 28/$r;
  font-weight: bold;
  color: #848484;
  line-height: 40/$r;
  box-sizing: border-box;
  margin: 24/$r 0 40/$r;
}
.expop .expop-sub{
  width: 400/$r;
  height: 80/$r;
  background: #FE8650;
  border-radius: 40/$r;
  text-align: center;
  font-size: 28/$r;
  font-weight: bold;
  color: #FEFEFE;
  line-height: 80/$r;
}
.expop-t{
  height: 70/$r;
}
.community-show .foot .foot-r-i{
  width: 400/$r!important;
  height: 78/$r!important;
  line-height: 78/$r;
  justify-content: center;
}
</style>